<template>
    <div>
        <div  class="listArea">
            <el-table :data="tableData" highlight textcenter style="width: 100%">
                <el-table-column prop="userImage" label="头像" align="center">
                    <template slot-scope="scope">
                        <el-image style="width: 30%; height: 30%" :src="scope.row.userImage"></el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="姓名" align="center"></el-table-column>
                <el-table-column prop="phone" label="手机号" align="center"></el-table-column>
                <el-table-column prop="time" label="申请时间" align="center"></el-table-column>
                <!-- <el-table-column label="操作" align="center" style="width: 100%; white-space: nowrap">
                    <template slot-scope="scope">
                        <el-button size="small" @click="editVenue(scope.row)">修改</el-button>
                        <el-button size="small" @click="del(scope.row)">删除</el-button>
                    </template>
                </el-table-column> -->
            </el-table>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    name: "Administrator",
    data() {
        return {
            dialogVisible: false,
            tableData: [{
                name: "1",
                phone: "15578947845",
                time: "17:11",
                userImage: "https://football-go.oss-cn-hangzhou.aliyuncs.com/pic/common/5eb699f89a754d2db7648b0a0c9b829f.jpg",
            },
            {
                name: "2",
                phone: "12458745689",
                time: "10:00",
                userImage: "https://football-go.oss-cn-hangzhou.aliyuncs.com/pic/common/5db2923c235443cdb59fd03398623e2c.jpg",
            },
            {
                name: "3",
                phone: "14254127895",
                time: "18:00",
                userImage: "https://football-go.oss-cn-hangzhou.aliyuncs.com/pic/common/34a6458ee8884468964607dc6526a92f.jpg",
            }],
        };
    },

    methods: {
    }
}
</script>


<style scoped>
.listArea {
    margin-top: 30px;
}
</style>